import React from "react";
import "./books-and-courses.styles.scss";

import CourseImage from "../../assets/images/course.png";
import Techie from "../../assets/images/smartmockupsHome.jpeg";
import Footer from "../../components/footer/footer.component";

const BookAndServicesPage = () => {
  const courseStore = [
    {
      title: "COURSE",
      subtitle: "CREATE AN OUTSTANDING RESUME FOR INTERNATIONAL ROLES",
      link: "https://www.lighthall.co/class/83e94cf6-730d-4c9c-9c23-80c25b4f977a",
      image: CourseImage
    },

    {
      title: "BOOK",
      subtitle: "A TECHIE’S GUIDE INTO BIG TECH COMPANIES",
      link: "/books-and-courses/techie's-guide",
      image: Techie
    }
  ];

  return (
    <div className="feature">
      <h1 className="heading  courses-heading">Books and Courses</h1>
      <div className="features">
        {courseStore.map((course) => (
          <a href={course.link} key={course.link}>
            <div className="features-card" key={course.title}>
              <div className="card-wrapper">
                <div className="features-header">
                  <h1 className="features-title">{course.title}</h1>
                  <p className="features-subtitle">{course.subtitle}</p>
                </div>

                <img src={course.image} alt="" />
              </div>
            </div>
          </a>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default BookAndServicesPage;
