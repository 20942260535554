import React from "react";
import "./awards.styles.scss";
import WomenTech from "../../assets/images/womenTech.png";
import SheCodeAfrica from "../../assets/images/sheCodeAfrica.jpeg";
import Footer from "../../components/footer/footer.component";

const Awards = () => {
  const awardStore = [
    {
      title: "Women Tech Network",
      subtitle: "Rising Star of the year",
      date: "10th October 2022",
      link: "https://www.womentech.net/nominee/Chisom/Nwokwu/79330",
      image: WomenTech
    },

    {
      title: "She Code Africa",
      subtitle: "Rising Star of the year",
      date: "12th November, 2022",
      link: "https://www.womentech.net/nominee/Chisom/Nwokwu/79330",
      image: SheCodeAfrica
    }
  ];

  return (
    <div className="feature">
      <h1 className="heading features-heading">Awards</h1>
      <div className="features">
        {awardStore.map((award) => (
          <a href={award.link} target="_blank">
            <div className="features-card" key={award.title}>
              <div className="card-wrapper">
                <div className="features-header">
                  <h1 className="features-title">{award.title}</h1>
                  <p className="features-subtitle">{award.subtitle}</p>
                  <p className="features-date">{award.date}</p>
                </div>

                <img src={award.image} alt="" />
              </div>
            </div>
          </a>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Awards;
