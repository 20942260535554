import React from "react";
import Adora from "../../assets/images/Adora.png";
import Ada from "../../assets/images/Ada.png";
import Ayomide from "../../assets/images/Ayomide.JPG";
import Jessica from "../../assets/images/Jessica.jpg";
import Timilehin from "../../assets/images/Timilehin.jpeg";
import Zainab from "../../assets/images/zainab.png";
import Emmanuel from "../../assets/images/Bakare.jpeg";
import Ini from "../../assets/images/inioluwa.jpg";
import Tobi from "../../assets/images/Tobi.jpeg";
import Eddi from "../../assets/images/Edidiong.jpeg";
import Olumide from "../../assets/images/Olumide.jpeg";
import Tobey from "../../assets/images/Tobey.jpeg";
import Rita from "../../assets/images/Rita.jpg";
import Ayodeji from "../../assets/images/Ayodeji.jpeg";

import { FaLinkedin, FaTwitter } from "react-icons/fa";

const Collaborators = () => {
  const collaboratorsStore = [
    {
      image: Adora,
      name: "Adora Nwodo",
      role: "Software Engineer, Microsoft",
      twitterLink: "https://twitter.com/AdoraNwodo",
      linkedInLink: "https://www.linkedin.com/in/adoranwodo/"
    },

    {
      image: Ayomide,
      name: "Ayomide Oyekanmi",
      role: "Site Reliability Engineer, Google",
      twitterLink: "https://twitter.com/_alternatewolf",
      linkedInLink: "https://www.linkedin.com/in/ayomideoyekanmi/"
    },

    {
      image: Jessica,
      name: "Jessica Ayodele",
      role: "Data Analyst at Sobeys",
      twitterLink: "https://twitter.com/jessica_xls",
      linkedInLink: "https://www.linkedin.com/in/jessicauwoghiren/"
    },

    {
      image: Timilehin,
      name: "Timilehin Fasipe",
      role: "Software Engineer, Google",
      twitterLink: "https://twitter.com/AdoraNwodo",
      linkedInLink: "https://www.linkedin.com/in/adoranwodo/"
    },

    {
      image: Zainab,
      name: "Zainab Ojutiku",
      role: "Software Engineer, Twitter",
      twitterLink: "https://twitter.com/jessica_xls",
      linkedInLink: "https://www.linkedin.com/in/zainab-ojutiku/"
    },

    {
      image: Ada,
      name: "Ada Nduka Oyom",
      role: "Founder, She Code Africa",
      twitterLink: "https://twitter.com/Kolokodess",
      linkedInLink: "https://www.linkedin.com/in/ada-nduka-oyom/"
    },

    {
      image: Emmanuel,
      name: "Emmanuel Bakare",
      role: "DevOps Engineer, Amazon (AWS)",
      twitterLink: "https://twitter.com/TiemmaBakare",
      linkedInLink: ""
    },

    {
      image: Ini,
      name: "Inioluwa Akindeye",
      role: "Software Engineer, Microsoft ",
      twitterLink: "https://twitter.com/iniakinleye",
      linkedInLink: "https://www.linkedin.com/in/inioluwaakinleye/"
    },

    {
      image: Tobi,
      name: "Tobi Ateniola",
      role: "Ex- Engineering Intern, Meta ",
      twitterLink: "https://twitter.com/tobirama___",
      linkedInLink: "https://t.co/Nq9dXcEo57"
    },

    {
      image: Eddi,
      name: "Edidiong Ekong",
      role: "Software Engineer, Amazon",
      twitterLink: "",
      linkedInLink: "https://www.linkedin.com/in/edidiong-ekong/"
    },

    {
      image: Olumide,
      name: "Olumide Ogundare",
      role: "Software Engineer, Microsoft ",
      twitterLink: "https://twitter.com/onyxolu",
      linkedInLink: "https://www.linkedin.com/in/olumideogundare/"
    },

    {
      image: Tobey,
      name: "Tobenna Ezike",
      role: "Software Engineer, Spotify	",
      twitterLink: "https://twitter.com/T0bey_",
      linkedInLink: "https://www.linkedin.com/in/tobenna-ezike/"
    },

    {
      image: Rita,
      name: "Rita Okonkwo",
      role: "Software Engineer, Microsoft",
      twitterLink: "https://mobile.twitter.com/icey_ree",
      linkedInLink: "https://mobile.twitter.com/icey_ree"
    },

    {
      image: Ayodeji,
      name: "Ayodeji Fabusuyi",
      role: "Ex- Engineering Intern, Goldman Sachs",
      twitterLink: "",
      linkedInLink: "https://www.linkedin.com/in/ayodeji-f-177084134/"
    }
  ];

  return (
    <div className="contributors">
      <h1>CONTRIBUTORS</h1>
      <p>The amazing people who contributed to the success of this project</p>

      <div className="contributor-wrapper">
        {collaboratorsStore.map((collaborator) => (
          <div className="contributor-card" key={collaborator.name}>
            <img src={collaborator.image} alt="" />

            <div className="co-about">
              <p>{collaborator.name}</p>
              <p className="role">{collaborator.role} </p>

              <div className="connect">
                <a href={collaborator.twitterLink}>
                  <FaTwitter />
                </a>
                <a href={collaborator.linkedInLink}>
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collaborators;
