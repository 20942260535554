import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.scss";
import Header from "./components/header/header.component";
import AboutPage from "./pages/about/about.component";
import Awards from "./pages/awards/awards.components";
import Blog from "./pages/blog/blog.component";
import BookAndServicesPage from "./pages/books-and-courses/books-and-courses.components";
import CertificationPage from "./pages/certifications/certifications.components";
import FeaturesPage from "./pages/features/features.components";
import Home from "./pages/home/home.component";
import PortfolioPage from "./pages/portfolio/portfolio.component";
import TalksPage from "./pages/talks/talks.component";
import TechieGuides from "./pages/techie-guide/techie-guide.component";

import Background from "./assets/images/AnimatedShape.svg";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <div className="public-wrapper">
          <div className="container-wrapper">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<AboutPage />} />
              <Route path="features" element={<FeaturesPage />} />
              <Route
                path="/books-and-courses"
                element={<BookAndServicesPage />}
              />
              <Route path="/books-and-courses/:id" element={<TechieGuides />} />
              <Route path="portfolio" element={<PortfolioPage />} />
              <Route path="/talks" element={<TalksPage />} />
              <Route path="/certifications" element={<CertificationPage />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/awards" element={<Awards />} />
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
