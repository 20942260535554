import React from "react";
import { useParams } from "react-router-dom";
import "./techie-guide.styles.scss";
import BookImage from "../../assets/images/smartmockups.png";
import Selar from "../../assets/images/selar.png";
import Amazon from "../../assets/images/amazon.f700b4d1.png";

import Inside from "../../components/inside/inside.components";
import Collaborators from "../../components/collaborators/collaborators.component";
import GetBook from "../../components/get-book/get-book.components";
import Author from "../../components/author/author.components";
import Footer from "../../components/footer/footer.component";

const TechieGuides = () => {
  const params = useParams();

  console.log(params);
  return (
    <div className="techie">
      <div className="intro-section">
        <img src={BookImage} alt="" />

        <div className="book-intro-content">
          <div className="book-intro-title">
            A Techie’s Guide <div>into</div> Big Tech <div>Companies</div>
          </div>
          <div className="book-author">By Chisom C. Nwokwu</div>
        </div>
      </div>
      <div className="available">
        <h4>Available Now On:</h4>
        <div className="brand">
          <img className="selar" src={Selar} alt="" />
          <img src={Amazon} alt="" />
        </div>

        <div className="btn">
          <a className="pre-order" href="https://selar.co/m/chisom_nwokwu">
            Buy on Selar
          </a>

          <a
            className="pre-order"
            href=" https://www.amazon.com/dp/9787948258?ref_=pe_3052080_397514860"
          >
            Buy on Amazon
          </a>
        </div>
      </div>
      <br />
      <div className="paragraph">
        <p>
          Relax and let me take you through a story, as someone who was in the
          tech space, I didn’t know it was possible to work at a big
          international firm, I felt I needed a ton of experience to qualify for
          such an opportunity.
        </p>

        <p>
          My perspective changed in 2020 when I attended a webinar that hosted
          software engineers working at Google and Facebook who just graduated
          from the University Of Lagos, Nigeria.
        </p>

        <p>
          Suddenly, I went from <span>“this might be possible”</span> to{" "}
          <span>“I know the steps I need to take.”</span> Fast forward to
          January 2021, I received numerous offers from international companies.
        </p>

        <p>
          Announcing my new job on social media, I got approximately 230+
          messages (yes, I counted).
        </p>

        <p>
          Everyone was asking the same question: “How can I get an offer from a
          big tech/ international company?”. Bingo! The idea for this book
          dropped.
        </p>

        <p>
          I decided to write a book so I could share all this information, which
          would not only benefit a subset of people, but also thousands of
          individuals around the globe. I just wanted to provide an easy guide
          for all interested persons and here it is!
        </p>
      </div>
      <div className="head">
        <h1>
          ARE YOU ASPIRING TO GET A JOB AT A BIG TECH COMPANY LIKE GOOGLE,
          MICROSOFT, META, AMAZON, TWITTER, (ETC) AND WONDERING YOU’RE THE STEPS
          TO TAKE?
        </h1>

        <h1>THIS BOOK IS CERTAINLY FOR YOU!</h1>

        <p>
          A techie’s guide into big tech companies is a complete roadmap that
          provides you with practical steps on how to land tech jobs at
          international companies. This book would guide you through what you
          need to learn in order to navigate a job in any of these companies.
        </p>
      </div>
      <div className="convinced"></div>
      <Inside />
      <Collaborators />
      <GetBook />
      <Author />
      <Footer />
    </div>
  );
};

export default TechieGuides;
