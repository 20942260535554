import React from "react";
import "./features.styles.scss";
import TechCabal from "../../assets/images/techCabal.png";
import Ventures from "../../assets/images/ventures.png";
import Channels from "../../assets/images/channels.png";
import Microsoft from "../../assets/images/microsoft.png";
import Taffd from "../../assets/images/10k.png";
import Growth from "../../assets/images/growthpodcast.png";
import Altschool from "../../assets/images/altsch.png";
import Educative from "../../assets/images/edpress.png";
import Qore from "../../assets/images/QoreID.png";
import Vanguard from "../../assets/images/vanguard.jpg";
import Piggy from "../../assets/images/piggy.jpg";
import Zikoko from "../../assets/images/zikoko.png";

import Footer from "../../components/footer/footer.component";

const FeaturesPage = () => {
  const featureStore = [
    {
      title: "Zikoko",
      subtitle: "The Nigerian Student Got into Microsoft",
      date: "14th march, 2023",
      link: "https://www.zikoko.com/money/this-nigerian-student-got-into-microsoft-with-zero-work-experience-how-she-do-am/?utm_source=twitter&utm_medium=cpc&utm_campaign=Hustle+print+weekly+campaign",
      image: Zikoko,
    },
    {
      title: "PiggyVest",
      subtitle: "Student Coder to Developer",
      date: "13th march, 2023",
      link: "https://blog.piggyvest.com/life/how-chisom-nwokwu-went-from-student-coder-to-developer-at-microsoft/",
      image: Piggy,
    },
    {
      title: "Vanguard",
      subtitle: "Harnessing the power of tech and self improvement",
      date: "14th February, 2023",
      link: "https://www.vanguardngr.com/2023/02/chisom-nwokwus-latest-book-harnessing-power-of-tech-and-self-improvement/",
      image: Vanguard,
    },
    {
      title: "QoreID",
      subtitle: "Innovation and Technology for Gender Equality",
      date: "28th march, 2023",
      link: "",
      image: Qore,
    },
    {
      title: "TechCabal",
      subtitle: "Rising in tech by lifting others",
      date: "8th september 2022",
      link: "https://techcabal.com/2022/09/08/how-chisom-nwokwu-is-helping-young-people-access-tech-opportunities/",
      image: TechCabal,
    },
    {
      title: "Ventures Africa",
      subtitle: "Living her dream job at microsoft",
      date: "27th April 2022",
      link: "https://venturesafrica.com/21-year-old-chisom-nwokwu-is-living-her-dream-at-microsoft/",
      image: Ventures,
    },
    {
      title: "Channels TV",
      subtitle: "Channels Beam - Ensuring access and Safety",
      date: "2nd May 2022",
      link: "https://www.youtube.com/watch?v=QGm5WIsHFkg",
      image: Channels,
    },
    {
      title: "Microsoft",
      subtitle: "Life at microsoft ADC",
      date: "22nd April 2022",
      link: "https://www.linkedin.com/pulse/chisom-nwokwu-how-technology-can-force-positive-change-ruth-ferland/?ocid=AIDN%2FA_TWITTER_oo_spl100003054192897",
      image: Microsoft,
    },
    {
      title: "TAFFD",
      subtitle: "Technology Recognition",
      date: "1st July 2022",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:6959856254093537280?utm_source=share&utm_medium=member_desktop",
      image: Taffd,
    },
    {
      title: "The Growth Podcast",
      subtitle: "The Tech Sis Episode",
      date: "2nd March 2022",
      link: "https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLnBvZGJlYW4uY29tL2dyb3dkaWFuY2UvZmVlZC54bWw/episode/Z3Jvd2RpYW5jZS5wb2RiZWFuLmNvbS8xMmI2OGE2YS04YmI3LTM2ZGYtYTcwMi0zYjJkMGUxNTVmYzE?sa=X&ved=0CAUQkfYCahcKEwiQ-pak7rn6AhUAAAAAHQAAAAAQAQ",
      image: Growth,
    },
    {
      title: "Altschool",
      subtitle: "International Women’s Day",
      date: "8th march 2022",
      link: "https://twitter.com/AltSchoolAfrica/status/1501517974599090176?s=20&t=gj002LoWkWg45hzExWea5A",
      image: Altschool,
    },
    {
      title: "Educative",
      subtitle: "Article of the week recognition",
      date: "7th June 2020",
      link: "https://dev.to/educative/edpresso-spotlight-chisom-nwokwu-3f2c",
      image: Educative,
    },
  ];

  return (
    <div className="feature">
      <h1 className="heading features-heading">Features</h1>
      <div className="features">
        {featureStore.map((feature) => (
          <a href={feature.link} target="_blank" key={feature.title}>
            <div className="features-card" key={feature.title}>
              <div className="card-wrapper">
                <div className="features-header">
                  <h1 className="features-title">{feature.title}</h1>
                  <p className="features-subtitle">{feature.subtitle}</p>
                  <p className="features-date">{feature.date}</p>
                </div>

                <img src={feature.image} alt="" />
              </div>
            </div>
          </a>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default FeaturesPage;
