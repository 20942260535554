import React, { useEffect, useRef } from "react";
import "./home.styles.scss";
import gsap from "gsap";
import { TweenMax } from "gsap/gsap-core";
import mainImage from "../../assets/images/mainpage.jpg";

import { FaLinkedin, FaTwitter, FaInstagram, FaGithub } from "react-icons/fa";

import { AiFillYoutube } from "react-icons/ai";

import { FiMail } from "react-icons/fi";

import { HiCamera } from "react-icons/hi";

const Home = () => {
  let app = useRef(null);
  let content = useRef(null);

  let info = useRef(null);

  useEffect(() => {
    TweenMax.to(app, 0, { css: { visibility: "visible" } });

    // Contents Animation
    fadeInUp(content, info);
  });

  const fadeInUp = (node1, node2) => {
    gsap.from([node1, node2], {
      duration: 1,
      y: 60,
      delay: 0.6,
      opacity: 0,
      ease: "power3.inOut",
      stagger: {
        amount: 0.4
      }
    });
  };

  return (
    <div ref={(e) => (app = e)} className="home">
      <div className="hero-container">
        <img className="home-image" src={mainImage} alt="" />

        <div className="hero-content">
          <div className="hero-content-inner" ref={(el) => (content = el)}>
            <h1>
              <div className="hero-content-line">
                <div className="intro">
                  <span>Hi</span>, My Name is
                </div>
              </div>

              <div className="hero-content-line">
                <div className="hero-name">Chisom Nwokwu.</div>
              </div>

              <div className="hero-content-line">
                <div className="hero-title">
                  (fondly called TechQueen)
                  <p>
                    I'm currently a Software Engineer based in Lagos, Nigeria.
                  </p>
                  <p>A Published Author and YouTuber.</p>
                </div>
              </div>
            </h1>
          </div>

          <div ref={(e) => (info = e)} className="connect">
            <span>
              <a target="_blank" href="https://github.com/Sommie09">
                <FaGithub className="fa" />
              </a>
            </span>

            <span>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/chisom-c-nwokwu-4b5787186/"
              >
                <FaLinkedin className="fa" />
              </a>
            </span>
            <span>
              <a target="_blank" href="https://twitter.com/tech_queen">
                <FaTwitter className="fa" />
              </a>
            </span>

            <span>
              <a target="_blank" href="mailto:chisomnwokwu09@gmail.com">
                <FiMail className="fa" />
              </a>
            </span>

            <span>
              <a target="_blank" href="https://www.instagram.com/chisom__nw/">
                <FaInstagram className="fa" />
              </a>
            </span>

            <span>
              <a
                target="_blank"
                href="https://www.notion.so/Chisom-s-Press-Kit-d4896ee658f841d9ba006e7953d9a8da"
              >
                <HiCamera className="fa" />
              </a>
            </span>

            <span>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCUYDigAIyBvtEUyDiAgcqLQ"
              >
                <AiFillYoutube className="fa" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
