import React from "react";
import "./collapsestyles.scss";

import "antd/dist/antd.css";

import { Collapse } from "antd";

const { Panel } = Collapse;

const CollapseCertifications = (props) => {
  const { textStore1, textStore2 } = props;
  return (
    <div className=" certification-collapse">
      <h1 className="heading features-heading cert">certifications</h1>
      <Collapse accordion defaultActiveKey={["1"]}>
        <Panel header="2022" key="1">
          {textStore1.map((text) => (
            <div key={text.id} className="year-container">
              <p className="name">{text.name}</p>

              <p>
                <span className="issue">
                  Issued by: <span className="issue-by">{text.issue}</span>{" "}
                </span>{" "}
                , <span className="date">{text.date} </span>{" "}
                <a
                  target="_blank"
                  className="credential-link"
                  href="https://drive.google.com/drive/folders/1LJGIOkDOpKwxdTfGXeKXxMCeajhS0cZa"
                >
                  show credential
                </a>
              </p>

              <br />
            </div>
          ))}
        </Panel>

        <Panel header="2018" key="2">
          {textStore2.map((text) => (
            <div key={text.id} className="year-container">
              <p className="name">{text.name}</p>

              <p>
                <span className="issue">
                  Issued by: <span className="issue-by">{text.issue}</span>{" "}
                </span>{" "}
                , <span className="date">{text.date} </span>{" "}
                <a
                  target="_blank"
                  className="credential-link"
                  href="https://drive.google.com/drive/folders/1LJGIOkDOpKwxdTfGXeKXxMCeajhS0cZa"
                >
                  show credential
                </a>
              </p>

              <br />
            </div>
          ))}
        </Panel>

        {/* <Panel header="2021" key="2">
          <p>{text}</p>
        </Panel>
        <Panel header="2022" key="3">
          <p>{text}</p>
        </Panel> */}
      </Collapse>
    </div>
  );
};

export default CollapseCertifications;
