import React, { useEffect, useRef } from "react";
import "./hamburger.styles.scss";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { FaLinkedin, FaTwitter, FaInstagram, FaGithub } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

import { AiFillYoutube } from "react-icons/ai";

import { HiCamera } from "react-icons/hi";

const Hamburger = ({ state }) => {
  const { clicked, initial } = state;

  // DOM Nodes to be animated
  let menu = useRef(null);
  let revealMenu = useRef(null);
  let revealMenuBackground = useRef(null);
  // let cityBackground = useRef(null);
  let line1 = useRef(null);
  let line2 = useRef(null);
  let line3 = useRef(null);
  let line4 = useRef(null);
  let line5 = useRef(null);
  let line6 = useRef(null);
  let line7 = useRef(null);
  let line8 = useRef(null);

  let info = useRef(null);

  useEffect(() => {
    if (clicked === false) {
      // close menu

      gsap.to([revealMenu, revealMenuBackground], {
        duration: 0.8,
        height: 0,
        ease: "power3.inOut",
        stagger: {
          amount: 0.07
        }
      });
      gsap.to(menu, {
        duration: 1,
        css: { display: "none" }
      });
    } else if (clicked === true || (clicked === true && initial === null)) {
      // open menu
      gsap.to(menu, {
        duration: 0,
        css: { display: "block" }
      });
      gsap.to([revealMenu, revealMenuBackground], {
        duration: 0,
        opacity: 1,
        height: "100%"
      });
      staggerReveal(revealMenuBackground, revealMenu);
      fadeInUp(info);
      staggerText(line1, line2, line3, line4, line5, line6, line7, line8);
    }
  }, [state]);

  const staggerReveal = (node1, node2) => {
    gsap.from([node1, node2], {
      duration: 0.8,
      height: 0,
      transformOrigin: "right top",
      skewY: 2,
      ease: "power3.inOut",
      stagger: {
        amount: 0.1
      }
    });
  };

  const staggerText = (
    node1,
    node2,
    node3,
    node4,
    node5,
    node6,
    node7,
    node8
  ) => {
    gsap.from([node1, node2, node3, node4, node5, node6, node7, node8], {
      duration: 0.8,
      y: 100,
      delay: 0.1,
      ease: "power3.inOut",
      stagger: {
        amount: 0.4
      }
    });
  };

  const fadeInUp = (node) => {
    gsap.from(node, {
      duration: 1,
      y: 60,
      delay: 0.8,
      opacity: 0,
      ease: "power3.inOut"
    });
  };

  const handleHover = (e) => {
    gsap.to(e.target, {
      duration: 0.3,
      y: 3,
      skewX: 6,
      ease: "power3.inOut"
    });
  };

  const handleHoverExit = (e) => {
    gsap.to(e.target, {
      duration: 0.5,
      y: -3,
      skewX: 0,
      ease: "power3.inOut"
    });
  };

  return (
    <div ref={(e) => (menu = e)} className="hamburger-menu">
      <div
        ref={(e) => (revealMenuBackground = e)}
        className="menu-secondary-background-color"
      ></div>
      <div ref={(e) => (revealMenu = e)} className="menu-layer">
        <div className="menu-city-background"></div>
        <div className="container">
          <div className="menu-links">
            <nav>
              <ul>
                <li>
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line1 = e)}
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line2 = e)}
                    to="/features"
                  >
                    Features
                  </Link>
                </li>
                <li className="books">
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line3 = e)}
                    to="/books-and-courses"
                  >
                    Books and Courses
                  </Link>
                </li>
                <li className="">
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line4 = e)}
                    to="/portfolio"
                  >
                    Portfolio
                  </Link>
                </li>
                <li className="">
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line5 = e)}
                    to="/talks"
                  >
                    Talks
                  </Link>
                </li>
                <li className="">
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line6 = e)}
                    to="/certifications"
                  >
                    Certifications
                  </Link>
                </li>

                <li className="">
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line7 = e)}
                    to="/blogs"
                  >
                    Blogs
                  </Link>
                </li>

                <li className="">
                  <Link
                    onMouseEnter={(e) => handleHover(e)}
                    onMouseOut={(e) => handleHoverExit(e)}
                    ref={(e) => (line8 = e)}
                    to="/awards"
                  >
                    Awards
                  </Link>
                </li>
              </ul>
            </nav>

            {/* <div ref={(e) => (info = e)} className="info">
                <h3>Hi, I'm Chisom 😇</h3>
                <p>
                  I am a Mobile Developer who loves building software solutions
                  that run on mobile devices! “Your mobile device has quickly
                  become the easiest portal into your digital self.” - Phil
                  Nickinson
                  <br /> <br />
                  Asides developing for mobile phones, I love community
                  building, managing IT projects, writing technical articles and
                  being the best version of myself!
                </p>
              </div> */}
          </div>
        </div>

        <div ref={(e) => (info = e)} className="locations">
          <span>
            <a target="_blank" href="https://github.com/Sommie09">
              <FaGithub className="fa" />
            </a>
          </span>

          <span>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/chisom-c-nwokwu-4b5787186/"
            >
              <FaLinkedin className="fa" />
            </a>
          </span>
          <span>
            <a target="_blank" href="https://twitter.com/tech_queen">
              <FaTwitter className="fa" />
            </a>
          </span>

          <span>
            <a target="_blank" href="https://www.instagram.com/chisom__nw/">
              <FaInstagram className="fa" />
            </a>
          </span>

          <span>
            <a target="_blank" href="mailto:chisomnwokwu09@gmail.com">
              <FiMail className="fa" />
            </a>
          </span>

          <span>
            <a
              target="_blank"
              href="https://www.notion.so/Chisom-s-Press-Kit-d4896ee658f841d9ba006e7953d9a8da"
            >
              <HiCamera className="fa" />
            </a>
          </span>

          <span>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCUYDigAIyBvtEUyDiAgcqLQ"
            >
              <AiFillYoutube className="fa" />
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
