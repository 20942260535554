import React from "react";
import "./blog.styles.scss";

import { BsClock } from "react-icons/bs";
import Footer from "../../components/footer/footer.component";

const Blog = () => {
  const blogSore = [
    {
      title: "Object-oriented concepts as explained to a 6-year-old",
      read: "3 mins read",
      link: "https://www.educative.io/answers/object-oriented-concepts-as-explained-to-a-6-year-old"
    },
    {
      title: "A Techie’s Journey",
      read: "7 min read",
      link: "https://medium.com/@chisomnwokwu09/a-techies-journey-6b4bfaa46339"
    },
    {
      title: "Data Persistence with ROOM",
      read: "2 min read",
      link: "https://www.educative.io/answers/data-persistence-with-room"
    },

    {
      title: "Dark Mode support on your Android App",
      read: "2 min read",
      link: "https://www.educative.io/answers/dark-mode-support-on-your-android-app"
    },

    {
      title: "What is inheritance in Kotlin? ",
      read: "2 min read",
      link: "https://www.educative.io/answers/what-is-inheritance-in-kotlin"
    },

    {
      title: "DiffUtil vs notifyDataSetChanged()",
      read: "1 min read",
      link: "https://www.educative.io/answers/diffutil-vs-notifydatasetchanged"
    },

    {
      title: "The Model-View-View-Model as explained to a 5-year-old",
      read: "3 min read",
      link: "https://www.educative.io/answers/the-model-view-view-model-as-explained-to-a-6-year-old"
    },
    {
      title: "How to create hyperlinks using android text view",
      read: "1 min read",
      link: "https://www.educative.io/answers/how-to-create-hyperlinks-using-android-textview"
    },

    {
      title: "Why should you use JWT’s",
      read: "2 min read",
      link: "https://www.educative.io/answers/why-should-you-use-jwts"
    }
  ];

  return (
    <div className="blog">
      <h1 className="heading blog-heading">Writings</h1>

      <div className="blog-container">
        {blogSore.map((blog) => (
          <a href={blog.link} key={blog.title}>
            <div className="blog-card">
              <h2 className="blog-title">{blog.title}</h2>
              <div className="read">
                <BsClock className="blog-icon" /> <span>{blog.read}</span>
              </div>
            </div>
          </a>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Blog;
