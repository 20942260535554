import React from "react";
import CollapseCertifications from "../../components/collapse/collapse.components";
import "./certifications.styles.scss";
import Footer from "../../components/footer/footer.component";

const textStore1 = [
  {
    id: 1,
    name: "Microsoft Certified: Power Platforms Fundamentals",
    issue: "Microsoft",
    date: "Dec 2020"
  },
  {
    id: 2,
    name: "Microsoft Certified: Azure Fundamentals",
    issue: "Microsoft",
    date: "Oct 2020"
  },
  {
    id: 3,
    name: "Java Developer Workshop",
    issue: "Packt",
    date: "Apr 2020"
  },
  {
    id: 4,
    name: "Microsoft Learn Student Ambassador",
    issue: "Microsoft",
    date: "Sept 2020"
  },
  {
    id: 5,
    name: "Internship Experience, UK",
    issue: "Bright Network",
    date: "Aug 2020 "
  },
  {
    id: 6,
    name: "Entrepreneurship Training",
    issue: "Jobberman",
    date: "Aug 2020"
  },
  {
    id: 7,
    name: "Kotlin Fundamentals",
    issue: "Codility",
    date: "June 2020"
  },
  {
    id: 8,
    name: "Build your own chatbot",
    issue: "IBM",
    date: "May 2020"
  },
  {
    id: 9,
    name: "Communication and Interpersonal Skills at Work",
    issue: "UC Irvine",
    date: "May 2020"
  },
  {
    id: 10,
    name: "Project Management Principles and Practices",
    issue: "IBM",
    date: "May 2020"
  },
  {
    id: 11,
    name: "Artificial Intelligence by Accenture",
    issue: "InsideSherpa",
    date: "Apr 2020"
  }
];

const textStore2 = [
  {
    id: 1,
    name: "Application Development (JAVA)",
    issue: "Aptech",
    date: "Nov 2018"
  },
  {
    id: 2,
    name: "Java Programming",
    issue: "Applitools",
    date: 2018
  }
];

const CertificationPage = () => {
  return (
    <div className="certifications">
      <CollapseCertifications
        textStore1={textStore1}
        textStore2={textStore2}
        className="cert-wrapper"
      />

      <br />
      <br />

      <Footer />
    </div>
  );
};

export default CertificationPage;
