import React from "react";
import "./about.styles.scss";
import aboutImage from "../../assets/images/_aboutme.jpg";
import Footer from "../../components/footer/footer.component";

const AboutPage = () => {
  return (
    <div className="about">
      <h1 className="heading  courses-heading">About me</h1>

      <div className="about-wrapper">
        <img src={aboutImage} alt="" />

        <div className="about-content">
          <h1>Hi, I'm Chisom</h1>

          <div className="about-paragraphs">
            <p>
              My name is Chisom Nwokwu (fondly likes to be called TechQueen) and
              I’m currently a Software Engineer based in Lagos, Nigeria.
            </p>
            <p>
              I started my coding journey in 2019 as an Android Developer. In
              August 2020, I launched my first app titled{" "}
              <a href="https://play.google.com/store/apps/details?id=com.chisom.igboamaka&hl=en&gl=US">
                “Igbo Amaka”
              </a>{" "}
              - a language-learning app with over 10,000 downloads on the Google
              play store
            </p>
            <p>
              In August 2021, I graduated from the University Of Nigeria, with a
              B.Sc in Computer Science right after I rounded up my internship
              with the Bank Of America, where I worked as a Technology Analyst.
            </p>
            <p>
              In November 2021, shortly after my graduation, I joined Microsoft
              as a Software Engineer where I currently work with the
              sustainability team, whose aim is to help the company achieve its
              goal of becoming carbon negative by the year 2030.
            </p>
            <p>
              Asides from engineering, I’m involved in other commendable
              activities in the tech ecosystem, where I’m constantly sharing
              opportunities, giving mentorship, and leaving the door open for
              others
            </p>

            <p>
              In October 2022, I got nominated as a “RISING STAR OF THE YEAR”,
              by the <a href="https://t.co/cmc3j5Hq9Z">Women Tech Network</a>{" "}
              and also by{" "}
              <a href="https://shecodeafrica.org/">She Code Africa</a> as
              someone who has made tremendous progress in a short period of
              time.
            </p>

            <p>
              With over 50+ speaking engagements, I spend my time helping people
              get started in tech, build early tech careers and land roles at
              top tech companies.
            </p>

            <p>
              I do this by hosting 1:1 sessions, masterclasses, and I also
              dedicate my time to posting daily job openings on my{" "}
              <a href="https://twitter.com/tech_queen">social media channels</a>
            </p>
            <p>
              November 2022, I launched my first book titled “A Techie’s Guide
              into Big tech companies, it’s a complete roadmap to help you land
              jobs at international tech firms like Google, Microsoft, Meta,
              Amazon etc.{" "}
              <a href="https://selar.co/m/chisom_nwokwu">Get a copy now!</a>
            </p>

            <p>
              As someone passionate about the tech ecosystem and seeing people
              excel, I plan to create more digital content around technology, my
              journey as a software engineer and helping others grow in tech
              through my{" "}
              <a href="https://www.youtube.com/channel/UCUYDigAIyBvtEUyDiAgcqLQ">
                youTube channel
              </a>
              .
            </p>

            <p>
              When I’m not busy with anything technical, I love doing 3 amazing
              things, cooking, playing the violin and helping people be their
              best selves.
            </p>
          </div>

          <div>
            <a href="https://superpeer.com/chisomnwokwu">Book a 1:1 </a> -{" "}
            <a href="https://twitter.com/tech_queen">Let's Chat</a>
          </div>

          <br />
        </div>
      </div>

      <br />
      <br />
      <Footer />
    </div>
  );
};

export default AboutPage;
