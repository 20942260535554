export const store2022 = [
  {
    id: 1,
    year: 2022,
    title: "GirlCode Bootcamp (Panel Session)",
    event: "Shecluded",
    date: "September 30th, 2022"
  },
  {
    id: 2,
    year: 2022,
    title: "Microsoft Resume Workshop",
    event: "Microsoft",
    date: "September 14th, 2022"
  },
  {
    id: 3,

    year: 2022,
    title: "Software Engineer, Open House",
    event: "Microsoft ADC",
    date: "August 26th, 2022"
  },
  {
    id: 4,
    year: 2022,
    title: "Breaking Into Tech & Leveraging Networking Opportunities ",
    event: "WeTech Conference",
    date: "August 27th, 2022"
  },
  {
    id: 5,
    year: 2022,
    title: "Career Development (Twitter Space)",
    event: "Lekan Olayinka",
    date: "August 27th, 2022"
  },
  {
    id: 6,
    year: 2022,
    title: "Personal Branding for Women in Tech Careers (Twitter Space)",
    event: "PAWEN",
    date: "August 11th, 2022"
  },
  {
    id: 7,
    year: 2022,
    title:
      "How to land internship roles in international organizations  (Twitter Space)",
    event: "MentorMe",
    date: "July 08th, 2022"
  },

  {
    id: 8,
    year: 2022,
    title: "Tech & Employment  (Twitter Space)",
    event: "TechBuild",
    date: "July 13th, 2022"
  },
  {
    id: 9,
    year: 2022,
    title: "How to transition & get started in tech",
    event: "Techbridge City",
    date: "June 11th, 2022"
  },

  {
    id: 10,
    year: 2022,
    title: "Securing global talent opportunities ",
    event: "Revamp",
    date: "May 07th, 2022"
  },

  {
    id: 11,
    year: 2022,
    title: "The Future of Web Development  (Twitter Space)",
    event: "Oracus Tech Community",
    date: "May 08th, 2022"
  },
  {
    id: 12,
    year: 2022,
    title: "Girls In ICT - Panel Session",
    event: "ChannelsTV",
    date: "May 22nd, 2022"
  },
  {
    id: 13,
    year: 2022,
    title: "How to ace technical interviews  (Twitter Space)",
    event: "Techrity",
    date: "May 28th, 2022"
  },
  {
    id: 14,
    year: 2022,
    title: "Creating a resume that attracts opportunities  (Twitter Space)",
    event: "Tunga",
    date: "May 31st, 2022"
  },
  {
    id: 15,
    year: 2022,
    title:
      "Overcoming tutorial Hell as a SelfTaught Developer (Twitter Spaces)",
    event: "Gloria Asuquo",
    date: "April 21st, 2022"
  },
  {
    id: 16,
    year: 2022,
    title: "Interview Ready (Twitter Spaces)",
    event: "Anthony Mays",
    date: "April 21st, 2022"
  },
  {
    id: 17,
    year: 2022,
    title: "Starting an early career in a big tech company",
    event: "The Afara Initiative",
    date: "March 25th, 2022"
  },
  {
    id: 18,
    year: 2022,
    title: "Women in Tech Event (Panel Session)",
    event: "Google’s Developers Student Club",
    date: "March 12th , 2022"
  },
  {
    id: 19,
    year: 2022,
    title: "Tech Skills in a global village",
    event: "GoMyCode",
    date: "March 11th , 2022"
  },
  {
    id: 20,
    year: 2022,
    title: "Women In Tech Workshop",
    event: "TechWomen",
    date: "February 18th, 2022"
  }
];

export const store2021 = [
  {
    id: 1,
    title: "Navigating the job application Process",
    event: "MTC - University Of Lagos",
    date: "August 14th, 2021"
  },
  {
    id: 2,
    title: "Getting Started with Android Development",
    event: "Women Tech Makers - Makurdi",
    date: "April 7th, 2021"
  },
  {
    id: 3,
    title: "Getting Started with Android Development",
    event: "NACOSS Computing Students",
    date: "April 2nd, 2021"
  },
  {
    id: 4,
    title: "Getting Started with Android Development",
    event: "Google Developers Student Club - Ilorin",
    date: "March 18th, 2021"
  }
];

export const store2020 = [
  {
    id: 1,
    title: "Advocating for yourself",
    event: "She Code Africa - OAU",
    date: "December 18th, 2020"
  },
  {
    id: 2,
    title: "Become an Android Developers",
    event: "Developers Student Club - UNN",
    date: "December 14th, 2020"
  },
  {
    id: 3,
    title: "A deep insight into tech",
    event: "Google Developers Student Club - Lagos",
    date: "November 20th, 2020"
  },
  {
    id: 4,
    title: "Getting Started in Tech",
    event: "GITHUB - UNN",
    date: "November 15th, 2020"
  },
  {
    id: 5,
    title: "The mobile development journey",
    event: "She Code Africa UNN",
    date: "November 20th, 2020"
  },
  {
    id: 6,
    title: "Getting Started with Ruby on rails",
    event: "Roar Nigeria Hub",
    date: "November 19th, 2020"
  },
  {
    id: 7,
    title: "Getting Started with Power Apps",
    event: "Microsoft Student Partners, UNN",
    date: "May 28th, 2020"
  }
];
