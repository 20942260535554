import React from "react";
import "./portfolio.styles.scss";
import { FaGithub } from "react-icons/fa";
import { FiLink2 } from "react-icons/fi";
import IgboImage from "../../assets/images/igboamaka.png";
import HydraMailImage from "../../assets/images/hydra.png";
import Footer from "../../components/footer/footer.component";

const PortfolioPage = () => {
  const projectStore = [
    {
      title: "Igbo Amaka",
      info: "Interactive language application for learning the Igbo Language This is a mobile application that enables individuals to learn the Igbo Language Interactively, using images, audio pronunciations and quizzes",

      skills: [
        "java",
        "audio media players",
        "material design",
        "animation libraries"
      ],

      github: "https://github.com/Sommie09",
      live: "https://play.google.com/store/apps/details?id=com.chisom.igboamaka&hl=en&gl=US",
      image: IgboImage
    },

    {
      title: "Hydra Mail",
      info: "Voice-Based Email Application. This is a voice-based email application that enables the visually challenged send mails without the use of keyboard operations or mouse clicks",

      skills: [
        "java",
        "android text to speech",
        "Mail SMPT",
        "Regular Expressions"
      ],
      github: "https://github.com/Sommie09/HydraMail",
      live: "https://github.com/Sommie09",
      image: HydraMailImage
    }
  ];

  return (
    <div className="project-container">
      <h1 className="heading features-heading cert">Projects</h1>
      {projectStore.map((project) => (
        <div className="project-card" key={project.title}>
          <div className="project-content">
            <h1 className="project-title">{project.title}</h1>

            <p className="project-info">{project.info}</p>

            <div className="project-technology">
              <span>{project.skills[0]}</span> <span>{project.skills[1]} </span>
              <span>{project.skills[2]}</span> <span>{project.skills[3]}</span>
              <span>{project.skills[4]}</span>
            </div>

            <div className="links">
              <a href={project.github} target="_blank">
                <FaGithub className="project-icon" />
              </a>
              <a href={project.live} target="_blank">
                <FiLink2 className="project-icon" />
              </a>
            </div>
          </div>

          <img className="portfolio-img" src={project.image} alt="" />
        </div>
      ))}
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default PortfolioPage;
