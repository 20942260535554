import React from "react";
import Image from "../../assets/images/Chisom_Image_author.png";

const Author = () => {
  return (
    <div className="author">
      <h1>ABOUT THE AUTHOR</h1>

      <div className="wrapper">
        <img src={Image} alt="" />

        <div className="content">
          <p>
            Chisom Nwokwu (fondly called TechQueen) is currently a Software
            Engineer based in Lagos, Nigeria who currently works with the
            Sustainability team at Microsoft
          </p>
          <br />
          <p>
            In August 2021, she graduated from the University Of Nigeria, with a
            B.Sc in Computer Science right after rounding up her internship with
            the Bank Of America, where she worked as a Technology Analyst.
          </p>
          <br />
          <p>
            With over 50+ speaking engagements she spends her time helping
            people get started in tech, build early tech careers and land roles
            at top tech companies.
          </p>

          <br />

          <p className="quote">
            “Your dreams are valid, and nothing is IMPOSSIBLE!”
          </p>
        </div>
      </div>
    </div>
  );
};

export default Author;
