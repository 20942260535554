import React from "react";
import "./footer.styles.scss";

const Footer = () => {
  return (
    <div className="footer">
      <p>
        {" "}
        Copyright &#169; {new Date().getFullYear()} Chisom Nwokwu - All Rights
        Reserved.
      </p>{" "}
    </div>
  );
};

export default Footer;
