import React from "react";

const Inside = () => {
  const insideStore = [
    {
      number: 1,
      title: "Personal Branding",
      body: "Building a solid portfolio, Starting a blog, the power of social media, networking into greatness, LinkedIn profile creation,setting up an engaging GitHub/LinkedIn account, how to attract recruiters and more…"
    },

    {
      number: 2,
      title: "Job Applications",
      body: "How to scout for international tech job adverts, steps in creating a winning Resume/ Cover Letter, secrets to requesting referrals, beginners guide to writing cold emails, do’s and don’ts in filling out a job application form and more….."
    },

    {
      number: 3,
      title: "Interview Preparation",
      body: "The Big O Notation, how to study data structures and algorithms, Step By Step Approach to solving a coding problem, coding interview patterns, System Design concepts for beginners, how to ace a technical/ behavioural interview, What to do before, during and after your interview, and more…"
    },

    {
      number: 4,
      title: "Moving Forward: Offer or Rejection",
      body: "Tips to negotiating an offer, Why you got rejected, How to handle rejections, and more…"
    },

    {
      number: 5,
      title: "6-Month Interview Preparation Guide",
      body: "A structured learning curriculum for preparing for a technical interview."
    },

    {
      number: 6,
      title: "Interview Resources and Templates",
      body: "Have access to a bank of interview preparation resources and templates"
    },

    {
      number: 7,
      title: "Personal Stories from the Author",
      body: "In each chapter, hear from the Author and learn about her mistakes and how she navigated getting multiple international job offers"
    },

    {
      number: 8,
      title: "My Big Tech Story",
      body: "Hear from software engineers at Google, Meta, Amazon, Twitter, Microsoft, and Spotify to learn how they navigated their way into these companies!"
    }
  ];

  return (
    <div className="inside">
      <h2>What’s Inside?</h2>

      <div className="inside-wrapper">
        {insideStore.map((inside) => (
          <div className="inside-card" key={inside.title}>
            <div className="number">{inside.number}</div>

            <div className="inside-content">
              <h1>{inside.title}</h1>
              <p>{inside.body} </p>
            </div>
          </div>
        ))}

        <div className="inside-card">
          <div className="number">9</div>

          <div className="inside-content">
            <h1>My Interview Journal</h1>
            <p>
              This journal assists you in tracking your job search progress,
              preparing for interviews and taking important notes as you go
              through your journey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inside;
