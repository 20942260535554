import React from "react";
import first from "../../assets/images/smartmockups2.png";
import second from "../../assets/images/hardcopy.jpg";

const GetBook = () => {
  const mockups = [
    {
      image: first
    },
    {
      image: second
    }
  ];
  return (
    <div className="book-mockup">
      <h1>GET THIS BOOK NOW</h1>

      <div className="book-mockup-wrapper">
        {mockups.map((mockup) => (
          <div className="book-mockup-card" key={mockup.image}>
            <img src={mockup.image} alt="" />
          </div>
        ))}
      </div>

      <a className="pre-order" href="https://selar.co/m/chisom_nwokwu">
        Buy on Selar
      </a>

      <a className="pre-order" href="#">
        Buy on Amazon
      </a>
    </div>
  );
};

export default GetBook;
