import React from "react";
import "./talks.styles.scss";
import { store2022, store2021, store2020 } from "./store";

import Talk1 from "../../assets/images/talk1.png";
import Talk2 from "../../assets/images/techwomen.png";
import Talk3 from "../../assets/images/talk3.png";
import CollapseTalks from "../../components/collapseTalks.component";
import Footer from "../../components/footer/footer.component";

const talkStore = [
  {
    title: "Breaking Into Tech & Leveraging Networking...",
    event: "WeTech Conference",
    date: "August 27th, 2022",
    image: Talk1
  },
  {
    title: "Girls in ICT Mentorship Program",
    event: "Microsoft",
    date: " 2022",
    image: Talk2
  },
  {
    title: "Women In Tech Workshop",
    event: "TechWomen",
    date: "February 18th, 2022",
    image: Talk3
  }
];

const TalksPage = () => {
  return (
    <div className="feature">
      <h1 className="heading features-heading">Talks</h1>
      <div className="features">
        {talkStore.map((talk) => (
          <a
            href="https://instinctive-sunspot-f9a.notion.site/Speaking-Engagments-19a4e27cedcc4421b863375c454cd929"
            target="_blank"
          >
            <div className="features-card talk" key={talk.date}>
              <div className="card-wrapper">
                <img src={talk.image} alt="" />

                <div className="features-header talks">
                  <h1 className="features-title">{talk.title}</h1>
                  <p className="features-subtitle">{talk.event}</p>
                  <p className="features-date">{talk.date}</p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>

      <CollapseTalks store1={store2022} store2={store2021} store3={store2020} />

      <Footer />
    </div>
  );
};

export default TalksPage;
