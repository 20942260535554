import React from "react";
import "../components/collapse/collapsestyles.scss";
import "antd/dist/antd.css";

import { Collapse } from "antd";

const { Panel } = Collapse;

const CollapseTalks = ({ store1, store2, store3 }) => {
  return (
    <div>
      <div className=" certification-collapse">
        <h1 className="heading features-heading cert">Others</h1>
        <Collapse accordion defaultActiveKey={["1"]}>
          <Panel header="2022" key="1">
            {store1.map((text) => (
              <div key={text.id} className="year-container">
                <p className="name">{text.title}</p>

                <p>
                  <span className="issue">
                    <span className="issue-by">{text.event}</span>{" "}
                  </span>{" "}
                  , <span className="date">{text.date} </span>{" "}
                  <a
                    target="_blank"
                    className="credential-link"
                    href="https://instinctive-sunspot-f9a.notion.site/Speaking-Engagments-19a4e27cedcc4421b863375c454cd929"
                  >
                    view
                  </a>
                </p>

                <br />
              </div>
            ))}
          </Panel>

          <Panel header="2021" key="2">
            {store2.map((text) => (
              <div key={text.id} className="year-container">
                <p className="name">{text.title}</p>

                <p>
                  <span className="issue">
                    <span className="issue-by">{text.event}</span>{" "}
                  </span>{" "}
                  , <span className="date">{text.date}</span>{" "}
                  <a
                    target="_blank"
                    className="credential-link"
                    href="https://instinctive-sunspot-f9a.notion.site/Speaking-Engagments-19a4e27cedcc4421b863375c454cd929"
                  >
                    show credential
                  </a>
                </p>

                <br />
              </div>
            ))}
          </Panel>

          <Panel header="2020" key="3">
            {store3.map((text) => (
              <div key={text.id} className="year-container">
                <p className="name">{text.title}</p>

                <p>
                  <span className="issue">
                    <span className="issue-by">{text.event}</span>{" "}
                  </span>{" "}
                  , <span className="date">{text.date}</span>{" "}
                  <a
                    target="_blank"
                    className="credential-link"
                    href="https://instinctive-sunspot-f9a.notion.site/Speaking-Engagments-19a4e27cedcc4421b863375c454cd929"
                  >
                    show credential
                  </a>
                </p>
                <br />
              </div>
            ))}
          </Panel>

          {/* <Panel header="2021" key="2">
          <p>{text}</p>
        </Panel>
        <Panel header="2022" key="3">
          <p>{text}</p>
        </Panel> */}
        </Collapse>
      </div>
      <br />
      <br />
    </div>
  );
};

export default CollapseTalks;
